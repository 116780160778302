<template>
    <div class="card lamp"
         :class="{'is_on': isLampOn}"
         @click="setStateLamp"
    >
        <div class="title">{{ title }}</div>
        <div class="state"></div>
        <span v-if="is_send" >***</span>

<!--        {{ isLampOn ? 'On': 'Off' }}-->
    </div>
</template>

<script>
import { computed, ref, toRefs } from 'vue'
import { ajax, app_store } from '../main'


export default {
    name: 'lamps',

    props: {
        title: String,
        node: Number,
        pin: Number,
    },

    setup(props){
        const {data_server, is_send_get_data, is_send_set_data} = toRefs(app_store)
        const is_send = ref(false)

        const {
            title,
            node,
            pin,
        } = toRefs(props)

        const isLampOn = computed(() => {
            // console.log(state.value)
            // console.log(state.value['3'])
            // console.log(state.value['3']['4'])
            if(data_server.value && data_server.value?.nodes?.[node.value]?.[pin.value]){
                return true
            }
            return false
        })



        const setStateLampSendData = () => {
            // if(!is_send_get_data.value) {
                ajax.post(`/smarthome/api_mk/set/${node.value}/`, {
                    'pin': pin.value,
                    'state': !isLampOn.value
                }, {
                    headers: {
                        'Authorization': localStorage['auth_token'] || ''
                    }
                }).then((v) => {
                    // console.log(v)
                    if(v.data?.id){
                        if(!data_server.value.nodes){
                            data_server.value.nodes = {}
                        }
                        data_server.value.nodes[v.data.id] = v.data.state
                    }
                }).finally(() => {
                    is_send_set_data.value = false
                    is_send.value = false
                })
            // }else{
            //     setTimeout(() => {
            //         setStateLampSendData()
            //     }, 100)
            // }
        }

        const setStateLamp = () => {
            if(!is_send_set_data.value) {
                is_send.value = true
                is_send_set_data.value = true
                setStateLampSendData()
            }
        }

        return {
            // state,
            title,
            isLampOn,
            setStateLamp,
            is_send,
        }
    }
}
</script>

<style scoped lang="scss">
    .lamp{
        //width: 100px;
        //height: 100px;
        //background-color: #242a4ab3;
        //display: flex;
        //justify-content: center;
        //align-items: center;
        //font-size: 24px;
        //color: #fff;
        //font-weight: 500;
        //cursor: pointer;
        //border-radius: 8px;

        .title{

        }

        .state{
            margin-top: 15px;
            height: 50px;
            width: 100%;
            position: relative;
            color: #fff;
            font-size: 30px;
            //text-align: center;

            &::after{
                content: '';
                position: absolute;
                top: 0;
                width: 30px;
                height: 30px;
                background-color: transparent;
                border: 2px solid #777fab;
                border-radius: 50%;
                margin-left: calc(50% - 15px);
            }
        }

        &.is_on .state::after{
            background-color: #cbe58d;
        }
    }
</style>
