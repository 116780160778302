<template>
    <template v-if="auth_token">
        <div id="list_app">
            <div class="logo">Умный дом</div>

            <div style="font-weight: 500;color: #fff;font-size: 18px;margin-bottom: 10px">Спальня свет</div>

            <div class="card_row">
                <lamps title="Основной"
                       :node="52"
                       :pin="0"
                />
                <lamps title="У окна"
                       :node="52"
                       :pin="2"
                />
            </div>
        </div>
    </template>

    <template v-else>
        <div id="auth">
            <div class="title">Авторизация</div>
            <input type="text" v-model="login_token" placeholder="Токен авторизации">

            <div class="btn" @click="onLogin">войти</div>
        </div>
    </template>

</template>

<script>

import Lamps from './components/lamps'
import { toRefs, ref, watch, computed } from 'vue'
import { app_store, ajax } from './main'
import { isEqual } from 'lodash'

export default {
    name: 'App',
    components: { Lamps },
    props: {},

    setup (props, { emit }) {
        const {
            data_server,
            // state,
            auth_token,
            is_send_set_data,
            is_send_get_data
        } = toRefs(app_store)
        const login_token = ref('')

        auth_token.value = localStorage['auth_token'] || ''

        ajax.interceptors.response.use(function (response) {
            // console.log('interceptors', response)
            if (response.data?.no_auth) {
                // ajax.defaults.headers.common['Authorization'] = '';
                localStorage['auth_token'] = ''
                auth_token.value = ''
            }

            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        });

        const onLogin = () => {
            // localStorage['auth_token']
            if(login_token.value) {
                ajax.get('/smarthome/api_mk/', {
                    headers: {
                        'Authorization': login_token.value
                    }
                })
                    .then(r => {
                        if (!r.data?.no_auth) {
                            // ajax.defaults.headers.common['Authorization'] = login_token.value;
                            localStorage['auth_token'] = login_token.value
                            // localStorage['testss'] = 'wwe'
                            auth_token.value = login_token.value
                            setTimeout(() => {
                                getServerData()
                            }, 200)
                        }else{
                            login_token.value = ''
                        }
                    })
            }
        }

        // const updateState = () => {
        //     const node = data_server.value?.node
        //     const nodes = data_server.value?.nodes
        //     if(nodes){
        //         const _state = {}
        //         nodes.forEach(k => {
        //             _state[k] = node[k].split(',')
        //         })
        //         state.value = _state
        //     }
        // }

        const getServerData = () => {
            if(auth_token.value) {
                // if(!is_send_set_data.value) {
                //     is_send_get_data.value = true
                    ajax.get('/smarthome/api_mk/', {
                        headers: {
                            'Authorization': auth_token.value
                        }
                    })
                        .then(r => {
                            if (r.data?.nodes && !isEqual(data_server.value, r.data)) {
                                data_server.value = r.data
                                // updateState()
                            }
                            // console.log(r.data)
                        })
                        .finally(() => {
                            // is_send_get_data.value = false
                            setTimeout(() => {
                                getServerData()
                            }, 10000)
                        })
                // }else{
                //     setTimeout(() => {
                //         getServerData()
                //     }, 1000)
                // }
            }
        }

        getServerData()

        // watch(auth_token, (v) => {
        //     console.log('auth_token')
        //     console.log(v)
        // })

        return {
            data_server,
            // state,
            auth_token,
            login_token,
            onLogin
        }
    }
}
</script>

<style lang="scss">
$mar: 24px;


@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    margin: 0;
    padding: 0;
    background: linear-gradient(154deg, rgba(59,71,141,1) 0%, rgba(5,15,38,1) 100%);
    height: 100vh;


    display: flex;
    flex-direction: column;

    *{
        box-sizing: border-box;
    }
}

img {
    border: none;
}
input{
    outline: none;
}

#app{
    height: 100%;
}

#auth{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;
    margin-top: 20%;

    .title{
        font-size: 24px;
        margin-bottom: 16px;
        font-weight: 500;
    }

    input{
        font-size: 24px;
        margin-bottom: 16px;
        width: 100%;
        padding: 10px;
        border: 1px solid #bdbdbd;
        border-radius: 4px;
    }

    .btn{
        background-color: #777fab;
        font-size: 24px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px 0;
        border-radius: 8px;
        width: 100%;
    }
}

#list_app{
    padding: $mar;

    .logo{
        font-size: 30px;
        font-weight: 500;
        margin: $mar 0;
        text-align: center;
    }

    .card{
        width: 100%;
        height: 120px;
        background-color: #242a4ab3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #fff;
        font-weight: 500;
        border-radius: 8px;
    }

    .card_row{
        display: flex;
        width: 100%;
        margin-bottom: $mar;

        .card{
            margin-right: $mar;

            &:last-child{
                margin-right: 0;
            }
        }
    }


}
</style>
