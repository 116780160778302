import App from './App.vue'
import axios_base from "axios";
import {createApp, reactive} from 'vue'
// import './registerServiceWorker'

/* axios */
const axios_headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
}
export const ajax = axios_base.create({
    baseURL: 'https://robton.ru',
    headers: axios_headers,
})

export const app_store = reactive({
    data_server: {},
    state: {},
    auth_token: localStorage['auth_token'] || '',
    is_send_set_data: false,
    is_send_get_data: false,
})

window.app_store = app_store


createApp(App).mount('#app')
